//
// features.scss
//

.features-content {
    .nav {
        .nav-link {
            padding: 15px;
            &.active {
                background: $white !important;
                box-shadow: $shadow;
            }
        }
    }
}