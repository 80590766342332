//
// variables.scss
//


@import url('https://fonts.googleapis.com/css?family=Muli:400,500,600,700|Rubik:400,500,700&display=swap');

// Color variables
$white:                     #ffffff;
$black:                     #000000;


$primary:                   #3956de;
$secondary:                 #6c757d;
$success:                   #2dd6b7;
$info:                      #1097bd;
$warning:                   #ffbb13;
$danger:                    #f43958;
$dark:                      #343a40;
$muted:                     #9b9bae;
$light:                     #fbfbfd;



$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "black": $black,
    "white": $white
);

$gradient:                 linear-gradient(to right, $primary 0%, rgba($primary, 0.6) 100%);

// Shadow
$shadow:                   2px 2px 12px -2px rgba($black, 0.08);

// Font
$font-primary:            'Muli', sans-serif;
$font-secondary:          'Rubik', sans-serif;