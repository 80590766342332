//
// project.scss
//

.project-box {
    position: relative;
    .project-overlay {
        position: absolute;
        background: rgba($black, 0.4);
        transform: translateY(100%);
        transition: all 0.5s;
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .project-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            left: 0;
            text-align: center;
            .project-icon {
                border: 1px solid $white;
                color: $white;
                height: 48px;
                width: 48px;
                line-height: 48px;
                margin: 0 auto;
                border-radius: 50px;
            }
        }
    }
    &:hover {
        .project-overlay {
            transform: translateY(0%);
            opacity: 1;
        }
    }
}