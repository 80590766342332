@import "bootstrap/dist/css/bootstrap.min.css";

/* fix */
    .step-select-field .form-check-container {
        padding: 0!important;
    }
    
    .form-check-input {
        margin-left: 10px!important;
        top: calc(50% - 12px);
    }
    
    .form-check-label {
        width: 100%;
        padding: 10px 0 10px 30px;
    }
    
    .form-check {
        padding-left: 0px!important;
    }
/* fix */

body {
    overflow-x: hidden;
    background: #f6f6f6;
}

.step-select-field .form-check-container {
    border: 1px solid #dadada;
    padding: 10px;
    margin-bottom: 10px;
}

.step-select-field .form-check-container:hover {
    background: #f6f6f6;
}

.steps-info {
    border-top: 2px solid #dadada;
}

.quiz-inner {
    background: #fff;
}

.quiz-container {
    width: 100%;
    height: 100vh;
    flex-direction: column;
}

.form-group {
    position: relative;
}

.imgCheckPhone {
    width: 20px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.PhoneInput {
    border-radius: 5px;
    display: flex!important;
}

.PhoneInputInput {
    outline: none;
    border: none;
}

.fullscreen-block {
    width: 100%;
    height: 100vh;
    color: #fff;
    background-size: cover;
    background-position: left;    
}

.fullscreen-block:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(-90deg, #000, #0000);
    animation: 5s linear 1s infinite alternate circle_bg;
    transition: 0.5s;
    transition-property: background;
}

a.glass-btn {
	position: relative;
	padding: 20px 10px;
	text-decoration: none;
	color: #fff;
    font-size: 14px;
    font-weight: bold;
	text-transform: uppercase;
	font-family: sans-serif;
	letter-spacing: 2px;
	overflow: hidden;
	background: rgba(255,255,255,.1);
	box-shadow: 0 5px 5px rgba(0,0,0.2);
}
a.glass-btn:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: rgba(255,255,255,.1);
}
a.glass-btn:after{
	content: '';
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg,transparent,rgba(255,255,255,.4),transparent);
	transition: 0.5s;
	transition-delay: 0.5s;
}
a.glass-btn:hover:after{
	left: 100%;
}
a.glass-btn span{
	position: absolute;
	display: block;
	transition: 0.5s ease;
}
a.glass-btn span:nth-child(1)
{
	top: 0;
	left: 0;
	width: 0;
	height: 1px;
	background: #fff;
}
a.glass-btn:hover span:nth-child(1)
{
	width: 100%;
	transform: translateX(100%);
}
a.glass-btn span:nth-child(3)
{
	bottom: 0;
	right: 0;
	width: 0;
	height: 1px;
	background: #fff;
}
a.glass-btn:hover span:nth-child(3)
{
	width: 100%;
	transform: translateX(-100%);
}
a.glass-btn span:nth-child(2)
{
	top: 0;
	left: 0;
	width: 1px;
	height: 0;
	background: #fff;
}
a.glass-btn:hover span:nth-child(2)
{
	height: 100%;
	transform: translateY(100%);
}
a.glass-btn span:nth-child(4)
{
	bottom: 0;
	right: 0;
	width: 1px;
	height: 0;
	background: #fff;
}
a.glass-btn:hover span:nth-child(4)
{
	height: 100%;
	transform: translateY(-100%);
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 768px) {
    a.glass-btn {
        display: inline-block;
    }
    
    h1 {
        font-size: 26px;
    }
    
    .quiz-container {
        height: auto;
        position: relative;
    }    
}

@media screen and (max-width: 450px) {
    h2 {
        font-size: 24px;
    }

    .fullscreen-block:before {
        background: linear-gradient(-45deg, #000, #0000);
    }
   
    .fullscreen-block {
        height: auto;
        min-height: 100vh;
        position: relative;
        background-position: center;
    }
}

@keyframes circle_bg { 
    from {
        background: linear-gradient(20deg, #000000a8, #0000);
    } 
    10% {
        background: linear-gradient(60deg, #000000a8, #0000);
    }
    20% {
        background: linear-gradient(100deg, #000000a8, #0000);
    }
    40% {
        background: linear-gradient(140deg, #000000a8, #0000);
    }
    50% {
        background: linear-gradient(180deg, #000000a8, #0000);
    }
    60% {
        background: linear-gradient(220deg, #000000a8, #0000);
    }
    70% {
        background: linear-gradient(260deg, #000000a8, #0000);
    }
    80% {
        background: linear-gradient(300deg, #000000a8, #0000);
    }
    90% {
        background: linear-gradient(340deg, #000000a8, #0000);
    }
    to { 
        background: linear-gradient(380deg, #000000a8, #0000);
    }  
}