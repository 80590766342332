//
// blog.scss
//

.blog-box {
    .blog-img {
        max-width: 150px;
    }
    .blog-box-content {
        margin-bottom: 30px;
    }
}